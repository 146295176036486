import { computed, Inject, Injectable, OnDestroy, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, concatMap, from, Observable, startWith, Subject, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { groupBy } from '@dougs/core/utils';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { Category, CategoryGroup } from '@dougs/operations/dto';
import { CategoryStateService } from '@dougs/operations/shared';

@Injectable()
export class CategoryModalComponentService implements OnDestroy {
  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  private readonly showPreferredCategories: WritableSignal<boolean> = signal(
    this.companyStateService.activeCompany?.id !== 125,
  );
  showPreferredCategories$: Signal<boolean> = this.showPreferredCategories.asReadonly();

  private readonly preferredCategories: WritableSignal<Category[]> = signal([]);
  preferredCategories$: Signal<Category[]> = this.preferredCategories.asReadonly();

  private readonly allCategoriesClicked: Subject<boolean> = new Subject<boolean>();

  formGroup: FormGroup = new FormGroup({
    search: new FormControl<string>('', { nonNullable: true }),
    isRefund: new FormControl<boolean>(false),
  });

  isExpenseSearch$: Observable<boolean> = this.search.valueChanges.pipe(
    map((search) => this.data.type === 'expense' && !!search && 'note de frais'.startsWith(search.toLowerCase())),
  );

  refreshAllCategories$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((company) => this.categoryStateService.getAllCategories(company.id)),
  );

  categories$: Observable<[CategoryGroup, Category[]][]> = combineLatest([
    this.companyStateService.activeCompanyIdChanged$,
    this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      distinctUntilChanged((prev, current) => prev.search === current.search && prev.isRefund === current.isRefund),
      debounceTime(300),
    ),
    this.categoryStateService.allCategories$,
    this.allCategoriesClicked.asObservable().pipe(startWith(false)),
  ]).pipe(
    tap(() => this.isLoading.set(true)),
    tap(([company, { search, isRefund }, allCategories, allCategoriesClicked]) => {
      if (search || isRefund || allCategoriesClicked) {
        this.showPreferredCategories.set(false);
      }
    }),
    concatMap(([company, { search, isRefund }, allCategories]) =>
      from(this.refreshCategories(company.id, isRefund, search)).pipe(
        map((categoryIds) => ({ categoryIds, allCategories })),
      ),
    ),
    map(({ categoryIds, allCategories }) => this.filterCategories(allCategories, categoryIds)),
    map((categories) => this.groupAndSortCategories(categories)),
    tap((groupedCategories) => this.preferredCategories.set(groupedCategories.flatMap(([, categories]) => categories))),
    tap(() => this.isLoading.set(false)),
  );

  shouldShowAvailableCategories$: Signal<boolean> = computed(
    () => !this.isLoading$() && !this.showPreferredCategories$(),
  );

  shouldShowPreferredCategories$: Signal<boolean> = computed(
    () => !this.isLoading$() && this.showPreferredCategories$(),
  );

  shouldShowFooter$: Observable<boolean> = combineLatest([
    this.isRefund.valueChanges.pipe(startWith(this.isRefund.value)),
    toObservable(this.showPreferredCategories$),
  ]).pipe(
    map(([isRefund, showPreferredCategories]) => !isRefund && showPreferredCategories && !!this.data.breakdownId),
  );

  selectedCategory?: Category;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      type: 'investment' | 'expense' | 'operation';
      categorySelected: number | undefined;
      breakdownId: number;
      operationId: number;
      breakdownIsInbound: boolean;
      operationDate?: string;
    },
    private readonly modalRef: ModalRef,
    private readonly companyStateService: CompanyStateService,
    private readonly categoryStateService: CategoryStateService,
    private readonly metricsService: MetricsService,
  ) {}

  get search(): FormControl<string> {
    return this.formGroup.get('search') as FormControl;
  }

  get isRefund(): FormControl<boolean> {
    return this.formGroup.get('isRefund') as FormControl;
  }

  private async refreshCategories(companyId: number, isRefund: boolean, search: string): Promise<number[]> {
    // We get all categories from a company because we don't know the breakdownId (expenses, investments...)
    if (!this.data.breakdownId) {
      return await this.categoryStateService.getCategoriesId(
        companyId,
        this.data.type,
        this.data.operationDate,
        isRefund,
        search,
      );
    }

    if (this.showPreferredCategories$()) {
      const preferredCategories: number[] = await this.categoryStateService.getOperationPreferredCategoriesId(
        companyId,
        this.data.breakdownId,
        this.data.operationId,
      );

      if (preferredCategories.length) {
        return preferredCategories;
      }
      this.showPreferredCategories.set(false);
    }

    return await this.categoryStateService.getOperationAvailableCategoriesId(
      companyId,
      this.data.breakdownId,
      this.data.operationId,
      isRefund,
      search,
    );
  }

  private filterCategories(allCategories: Category[], categoryIds: number[]): Category[] {
    return allCategories.filter((category: Category) => categoryIds.includes(category.id));
  }

  private groupAndSortCategories(categories: Category[]): [CategoryGroup, Category[]][] {
    return Object.values(
      groupBy(
        (category: Category) => ({
          key: category.groupId.toString(),
          groupInfo: category.group,
        }),
        categories.sort((a, b) => a.wording.localeCompare(b.wording)),
      ),
    );
  }

  selectCategory(category: Category): void {
    this.selectedCategory = category;
    this.modalRef.close(category);
  }

  onShowAllAvailableCategories(): void {
    this.allCategoriesClicked.next(true);
    this.showPreferredCategories.set(false);
  }

  ngOnDestroy(): void {
    if (this.search?.value?.length > 0) {
      this.metricsService.pushMixpanelEvent('Accounting Categories Researched', {
        'Search Text': this.search.value,
        'Selected Category': this.selectedCategory?.wording ?? null,
      });
    }
  }
}
