import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AutofocusDirective,
  BlankStateComponent,
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  EmptyObjectPipe,
  FormFieldComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  TrackByPipe,
} from '@dougs/ds';
import { CategoryModalComponentService } from '../../services/category-modal.component.service';
import { CategoryContentComponent } from './category-content/category-content.component';

@Component({
  selector: 'dougs-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    CheckboxComponent,
    LoaderComponent,
    BlankStateComponent,
    PanelInfoComponent,
    CategoryContentComponent,
    EmptyObjectPipe,
    ControlFormFieldDirective,
    AutofocusDirective,
    TrackByPipe,
  ],
  providers: [CategoryModalComponentService],
})
export class CategoryModalComponent {
  constructor(public readonly categoryModalComponentService: CategoryModalComponentService) {}

  @ViewChild('searchInputElement')
  searchInputElement!: ElementRef;

  focusSearchOnRefundChange$: Observable<void> = this.categoryModalComponentService.isRefund.valueChanges.pipe(
    map(() => this.searchInputElement?.nativeElement?.focus()),
  );
}
